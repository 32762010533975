// sheets.tsx
import { Workbook, WorkbookInstance } from '@fortune-sheet/react';
import '@fortune-sheet/react/dist/index.css';
import './App.css';
import ChatBot from './ChatBot';
import CodeEditor from './CodeEditor';
import React, { useRef, useState } from 'react';

type Row = { [key: string]: any };

export function App() {
  const [sqlCode, setSqlCode] = useState<string>('');
  const [workbookData, setWorkbookData] = useState<any[]>([
    { name: 'Sheet1', defaultColWidth: 100 ,data: [] },
  ]);
  const [savedCodeArray, setSavedCodeArray] = useState<string[]>([]);
  const [codeExplanationMap, setCodeExplanationMap] = useState<{ [key: string]: string }>({});
  const [sqlDataMap, setSqlDataMap] = useState<{ [sqlCode: string]: any }>({});

  const workbookRef = useRef<WorkbookInstance>(null);

  const settings = {
    row: 700
  }
  // Function to handle SQL responses generated by OpenAI and update the CodeEditor
  const HandleOpenAiGeneratedSqlResponse = (generatedSqlCode: string) => {
    setSqlCode(generatedSqlCode);
  };

  // Function to map the SQL result to Workbook-compatible format
  const mapSqlResultToWorkbook = (result: any): [string[], Row[]] | null => {
    if (!result) return null;
    const data = result.data

    const headers = result.column_names 
    const rows = data.map((row: any) => {
      const rowData: Row = {};
      headers.forEach((header: string) => {
        rowData[header] = row[header];
      });
      return rowData;
    });

    return [headers, rows];
  };

  // Function to handle SQL result and populate the Workbook
  const HandleSqlResult = (executedSqlCode: string, result: any) => {
    console.log('Executed SQL Code:', executedSqlCode);
    // Update the mapping of sqlCode to data
    setSqlDataMap((prevMap) => ({
      ...prevMap,
      [executedSqlCode]: result,
    }));

    // Map the SQL result to the Workbook data structure
    const mappedResult = mapSqlResultToWorkbook(result);
    if (!mappedResult) {
      return;
    }

    const [headers, rows] = mappedResult;

    if (workbookRef.current) {
      const sheet = workbookRef.current.getSheet();
      sheet.celldata.forEach((c_data) => {
        workbookRef.current?.setCellValue(c_data.r, c_data.c, null);
      });

      // Set headers
      headers.forEach((header, colIdx) => {
        workbookRef.current!.setCellFormat(0, colIdx, 'ff', 1);
        workbookRef.current!.setCellValue(0, colIdx, header, { index: 0 });
      });
      // Set data rows
      rows.forEach((rowData, rowIdx) => {
        headers.forEach((header, colIdx) => {
          const cellValue = rowData[header];
          workbookRef.current!.setCellFormat(rowIdx + 1, colIdx, 'ff', 1);
          workbookRef.current!.setCellValue(rowIdx + 1, colIdx, cellValue, { index: 0 });
        });
      });
    }
  };

  // Function to handle saving code from CodeEditor
  const handleSaveCode = (code: string) => {
    setSavedCodeArray((prevArray) => [...prevArray, code]);
  };

  // Function to handle new code explanation and SQL code mapping
  const handleNewCodeExplanationMapping = (codeExplanation: string, sqlCode: string) => {
    setCodeExplanationMap((prevMap) => ({
      ...prevMap,
      [codeExplanation]: sqlCode,
    }));
  };

  return (
    <div className="app-container">
      <div className="workbook-container">
        <Workbook ref={workbookRef} data={workbookData} {...settings}/>
      </div>
      <div className="bottom-container">
        <ChatBot
          onSQLResponse={(sqlCode, data) => {
            HandleOpenAiGeneratedSqlResponse(sqlCode);
            if (data) {
              console.log('Adding SQL output to the Workbook');
              HandleSqlResult(sqlCode, data);
            }
          }}
          savedCodeArray={savedCodeArray}
          currentCode={sqlCode}
          workbookRef={workbookRef}
          onNewCodeExplanationMapping={handleNewCodeExplanationMapping}
          codeExplanationMap={codeExplanationMap}
          sqlDataMap={sqlDataMap}
          onSaveCode={handleSaveCode}
        />
        <CodeEditor
          code={sqlCode}
          onSQLResponse={HandleSqlResult}
          onSaveCode={handleSaveCode}
          codeExplanationMap={codeExplanationMap}
          sqlDataMap={sqlDataMap} // Pass the mapping if needed
        />
      </div>
    </div>
  );
}

export default App;
