// ChatBot.tsx
import React, { RefObject, useState, KeyboardEvent, useRef, useEffect } from 'react';
import axios from 'axios';
import { format } from 'sql-formatter';
import { flags } from './flags';
import { WorkbookInstance } from '@fortune-sheet/react';

const MAX_CHARS_SAMPLE_INPUT = 5000;

interface Message {
  sender: 'user' | 'bot';
  text: string;
  codeExplanation?: string;
}

interface ChatBotProps {
  onSQLResponse: (sqlCode: string, data?: any) => void;
  savedCodeArray: string[];
  currentCode: string;
  workbookRef: React.RefObject<WorkbookInstance>;
  onNewCodeExplanationMapping: (codeExplanation: string, sqlCode: string) => void;
  codeExplanationMap: { [key: string]: string };
  sqlDataMap: { [sqlCode: string]: any }; // Include sqlDataMap in props
  onSaveCode: (sqlCode: string) => void;
}

// Global string variable for the input prompt template
const input_prompt_template: string = `
You are an expert SQL Analyst. Answer the following input question and answer in a JSON structure based on the requirements below:
{
code_explanation: *If the question is related to writing SQL, return a string that describes what the SQL Code does, otherwise set as null.*
sql_code: *If the question is related to writing SQL, return the PostgreSQL code that addresses the question, otherwise set as null.*
}

Source Table Name: 
  uncleaned_ds_jobs

Table Schema:
  column_name	data_type	is_nullable
  index_column	text	YES
  Job Title	text	YES
  Salary Estimate	text	YES
  Job Description	text	YES
  Rating	text	YES
  Company Name	text	YES
  Location	text	YES
  Headquarters	text	YES
  Size	text	YES
  Founded	text	YES
  Type of ownership	text	YES
  Industry	text	YES
  Sector	text	YES
  Revenue	text	YES
  Competitors	text	YES

SQL Conditions:
  - Avoid using select *, and write out each column name instead.
  - All column names should be in double quotes
  - Only use SELECT statements
  - Avoid using CTEs
  - Only include the specified columns, unless there were no specified columns
  - If question is numbers related and column is text, make sure to cast the column to int or float

IF THE PROMPT MENTIONS THAT THE QUERY IS WRONG, PLEASE ATTEMPT TO WRITE A NEW CORRECTED QUERY AND PROVIDE CODE_EXPLANATION.

IF ANYTHING IN THE PROMPT IS RELATED TO ANALYSIS, ASSUME THAT YOU WILL NEED TO GENERATE A SQL QUERY.
REMEMBER THAT WE ARE USING PostgreSQL. DO NOT RETURN A GREETING OR INTRODUCTION. ONLY RETURN THE JSON AS THE OUTPUT.
{input_question}
`;

const ChatBot: React.FC<ChatBotProps> = ({
  onSQLResponse,
  savedCodeArray,
  currentCode,
  workbookRef,
  onNewCodeExplanationMapping,
  codeExplanationMap,
  sqlDataMap, // Receive sqlDataMap via props
  onSaveCode,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Function to adjust textarea height
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Use effect to adjust height on input change
  useEffect(() => {
    adjustTextareaHeight();
  }, [userInput]);

  // Function to send user message and fetch bot response
  const handleSend = async () => {
    if (!userInput.trim()) return;

    // Use the current code from CodeEditor
    const sql_context = currentCode || '';

    // Format the user input with the input_prompt_template
    let formattedInput = input_prompt_template.replace('{input_question}', userInput);

    // If sqlDataMap exists for current sql_context, add data[0] to formattedInput
    let sampleData = ''
    if (flags.includeCellInputInPrompt && sql_context && sqlDataMap && sqlDataMap[sql_context]) {
      const data = sqlDataMap[sql_context];

      // column selection 
      if (workbookRef.current) {
        const selection = workbookRef.current.getSelection();
        // only consider the case where a single column in a single worksheet is selected
        if (selection && selection.length === 1 && selection[0].column[0] === selection[0].column[1]) {
          const columnIndex = selection[0].column[0];
          const header = workbookRef.current.getCellValue(0, columnIndex);

          // TODO: add error handling for long header length
          sampleData = `header: ${header}\n`;

          const [firstRow, lastRow] = selection[0].row;
          for (let i = firstRow; i < lastRow && sampleData.length < MAX_CHARS_SAMPLE_INPUT; i++) {
            console.log({sampleData});
            if (i === 0 || data[i] === undefined) continue;
            sampleData += JSON.stringify(data[i][header]) + '\n';
          }
        }
      }

      // use full rows of data if no column selected
      if (!sampleData && Array.isArray(data) && data.length > 0) {
        const headerRow = data[0];
        const dataRows = data.slice(1);

        // Add header row
        sampleData = Object.keys(headerRow).join('\t') + '\n';
        let currentLength = sampleData.length;

        // Function to sort object keys by value length
        const sortObjectByValueLength = (obj: any) => {
          return Object.fromEntries(
            Object.entries(obj).sort(([,a], [,b]) => String(a).length - String(b).length)
          );
        };

        // Build rows until MAX_CHARS is reached
        for (const row of dataRows) {
          const sortedRow = sortObjectByValueLength(row);
          let partialRow: string[] = [];

          for (const [key, value] of Object.entries(sortedRow)) {
            const tempValue = String(value).replace(/\t/g, ' ').replace(/\n/g, ' '); // Replace tabs and newlines
            const tempRow = [...partialRow, tempValue];
            const tempRowString = tempRow.join('\t');
            
            if (currentLength + tempRowString.length + 1 > MAX_CHARS_SAMPLE_INPUT) {
              break;
            }
            
            partialRow.push(tempValue);
          }

          const rowString = partialRow.join('\t');
          if (currentLength + rowString.length + 1 > MAX_CHARS_SAMPLE_INPUT) {
            break;
          }

          sampleData += rowString + '\n';
          currentLength += rowString.length + 1;

          if (currentLength >= MAX_CHARS_SAMPLE_INPUT) {
            break;
          }
        }
      }

      if (sampleData) {
        sampleData = `This is a sample output from context sql: \n${sampleData}\n`
      }
    }

    // If there is a current SQL code, include it in the prompt
    if (sql_context) {
      formattedInput = `If the question is SQL related, consider the following context:\n'''\n${sql_context}\n'''\n` + sampleData + formattedInput;
    }

    console.log('Looking at formattedInput:', formattedInput);

    // Ensure sender is 'user'
    const newMessages: Message[] = [...messages, { sender: 'user', text: userInput }];
    setMessages(newMessages);
    setUserInput('');
    setLoading(true);

    try {
      // Fetch response from OpenAI using the formatted input
      const responseString = await fetchOpenAIResponse(formattedInput);

      // Parse the JSON string into an object
      const response = JSON.parse(responseString);

      // Handle SQL or non-SQL response
      if (response.sql_code) {
        // Format SQL code using sql-formatter
        const formattedSQL = format(response.sql_code);

        // Update CodeEditor with formatted SQL code
        onSQLResponse(formattedSQL);

        // Update the mapping in App component
        onNewCodeExplanationMapping(response.code_explanation || '', formattedSQL);

        // Execute the SQL query and populate the spreadsheet
        await executeSqlQuery(formattedSQL);

        // Update chat with code explanation
        const botMessage: Message = {
          sender: 'bot',
          text: response.code_explanation || 'SQL Code provided and executed.',
          codeExplanation: response.code_explanation || '',
        };
        setMessages([...newMessages, botMessage]);
      } else {
        // Update chat with non-SQL response
        const botMessage: Message = {
          sender: 'bot',
          text: response.non_sql_response || 'No relevant information provided.',
        };
        setMessages([...newMessages, botMessage]);
      }
    } catch (error) {
      console.error('Error parsing response or executing SQL:', error);
      const errorMessage: Message = { sender: 'bot', text: 'Error getting response from OpenAI or executing SQL.' };
      setMessages([...newMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  // New function to execute SQL query
  const executeSqlQuery = async (sqlCode: string) => {
    try {
      const response = await axios.post('http://localhost:8000/run-query', { query: sqlCode });
      const data = response.data;
      const column_names = data.column_names;

      if (data.error) {
        console.error('SQL Execution Error:', data.error);
        throw new Error(data.error);
      } else {
        console.log('SQL Query Result:', data);
        // Pass the executed SQL query and result back to the parent component (App)
        onSQLResponse(sqlCode, data);

        // Automatically save the current SQL code to savedCodeArray
        onSaveCode(sqlCode);
      }
    } catch (error) {
      console.error('Error executing SQL query:', error);
      throw error;
    }
  };

  // Function to call OpenAI API and return the response as a JSON string
  const fetchOpenAIResponse = async (userMessage: string): Promise<string> => {
    // Get the API key from the .env file
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error('OpenAI API key is missing');
      return JSON.stringify({
        code_explanation: null,
        sql_code: null,
        non_sql_response: 'Error: API key not provided.',
      });
    }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    };

    const data = {
      model: 'gpt-4',
      messages: [{ role: 'user', content: userMessage }],
      max_tokens: 2000,
      temperature: 0.0, // You can adjust this value based on your needs
    };

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        data,
        { headers }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error fetching response from OpenAI:', error);
      return JSON.stringify({
        code_explanation: null,
        sql_code: null,
        non_sql_response: "Sorry, I couldn't connect to OpenAI.",
      });
    }
  };

  // Function to handle the button click
  const handleCodeExplanationClick = (codeExplanation: string) => {
    const sqlCode = codeExplanationMap[codeExplanation];
    if (sqlCode) {
      // Update the CodeEditor with the sqlCode
      onSQLResponse(sqlCode);
    } else {
      console.error('SQL code not found for the given code explanation.');
    }
  };

  // Modify handleKeyDown to include height adjustment
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === 'Tab') {
      e.preventDefault();
      const textarea = textareaRef.current;
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newValue = userInput.substring(0, start) + '\t' + userInput.substring(end);
        setUserInput(newValue);
        // Set cursor position after tab
        requestAnimationFrame(() => {
          textarea.selectionStart = textarea.selectionEnd = start + 1;
        });
      }
    }
    // Adjust height after key press
    adjustTextareaHeight();
  };

  const formatMessage = (text: string) => {
    return text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line.split('\t').map((segment, j) => (
          <React.Fragment key={j}>
            {j > 0 && <span style={{ marginLeft: '2em' }}></span>}
            {segment}
          </React.Fragment>
        ))}
        {i < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  // Define styles using React.CSSProperties type
  const styles = {
    chatContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #ccc',
      borderRadius: '10px',
      overflow: 'hidden',
      margin: '0 10px 0 0',
    } as React.CSSProperties,
    chatWindow: {
      flex: 1,
      padding: '10px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    } as React.CSSProperties,
    message: {
      maxWidth: '70%',
      padding: '10px',
      borderRadius: '10px',
      marginBottom: '10px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    } as React.CSSProperties,
    messageText: {
      flex: 1,
      wordWrap: 'break-word',
    } as React.CSSProperties,
    messageButton: {
      marginLeft: '10px',
      padding: '5px 10px',
      border: 'none',
      backgroundColor: '#28a745',
      color: 'white',
      cursor: 'pointer',
      borderRadius: '5px',
      alignSelf: 'flex-start',
    } as React.CSSProperties,
    inputContainer: {
      display: 'flex',
      borderTop: '1px solid #ccc',
      alignItems: 'flex-end', // Align items to the bottom
    } as React.CSSProperties,
    input: {
      flex: 1,
      padding: '10px',
      border: 'none',
      outline: 'none',
      resize: 'none', // Disable manual resizing
      overflow: 'hidden', // Hide scrollbar
      minHeight: '50px',
      maxHeight: '200px', // Set a maximum height
    } as React.CSSProperties,
    sendButton: {
      padding: '10px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
    } as React.CSSProperties,
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.chatWindow}>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              ...styles.message,
              alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              backgroundColor: msg.sender === 'user' ? '#d1e7dd' : '#f8d7da',
            }}
          >
            <div style={styles.messageText}>
              {formatMessage(msg.text)}
            </div>
            {/* Render the button if the message has a codeExplanation */}
            {msg.sender === 'bot' && msg.codeExplanation && (
              <button
                onClick={() => handleCodeExplanationClick(msg.codeExplanation!)}
                style={styles.messageButton}
              >
                Load SQL Code
              </button>
            )}
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <textarea
          ref={textareaRef}
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value);
            adjustTextareaHeight(); // Adjust height on change
          }}
          onKeyDown={handleKeyDown}
          placeholder="Type a message... (Shift + Enter for new line, Tab for indentation)"
          style={styles.input}
          disabled={loading}
        />
        <button
          onClick={handleSend}
          style={styles.sendButton}
          disabled={loading}
        >
          {loading ? '...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default ChatBot;
